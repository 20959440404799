<template>
  <div>
    <div class="news-header">Информация Службы срочных донесений (ССД)</div>
    <div class="news-aside">
      Архив сообщений (новый)
      Архив сообщений (до 12.2016)
    </div>

    <div v-for="article in news" :key="article.title"  class="news-article">
      <div >
        <div class="news-title"><a :href="article.link" class="news-link">{{article.title}}</a></div>
        <div class="news-body">{{article.body}}</div>
        <div class="news-meta">{{article.meta}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'news',
    data() {
      return {
        news: [
          {
            title: 'Информационное сообщение о сильном землетрясении на границе Китая и Кыргызcтана 22 января 2024 г.',
            body: '22 января 2024 года в 18 час 09 мин гринвичского времени (21 час 09 мин московского времени) на границе Китая и Кыргызcтана произошло землетрясение (в эпицентре разрушительное по шкале ШСИ-17) с М=7.3.\n' +
              '        ШСИ-17 - Шкала сейсмической интенсивности. Землетрясения. ГОСТ Р 57546–2017.',
            meta: 'Время создания: 22/01/2024 23:50:49 Последнее обновление: 06/02/2024 12:27:14',
            link: 'http://mseism.gsras.ru/EqInfo/RequestsHandler?cmd=toinfmsg&imid=258'
          },{
            title: 'Информационное сообщение о сильном землетрясении в районе озера Байкал 15 января 2024 г.',
            body: '15 января 2024 года в 12 час 52 мин гринвичского времени (15 час 52 мин московского времени) в районе озера Байкал произошло землетрясение (в эпицентре сильное по шкале ШСИ-17) с М=5.5.\n' +
              '      ШСИ-17 - Шкала сейсмической интенсивности. Землетрясения. ГОСТ Р 57546–2017.',
            meta: 'Время создания: 15/01/2024 17:31:45 Последнее обновление: 18/01/2024 17:43:00',
            link: 'http://mseism.gsras.ru/EqInfo/RequestsHandler?cmd=toinfmsg&imid=257'
          },{
            title: 'Информационное сообщение о сильном землетрясении на западном побережье острова Хонсю, Япония, 1 января 2024 г.',
            body: '1 января 2024 года в 07 час 10 мин гринвичского времени (10 час 10 мин московского времени) на западном побережье острова Хонсю, Япония, произошло сильное землетрясение (в эпицентре катастрофическое по шкале ШСИ-17) с М=7.3.\n' +
              '      ШСИ-17 - Шкала сейсмической интенсивности. Землетрясения. ГОСТ Р 57546–2017.',
            meta: 'Время создания: 01/01/2024 13:23:35 Последнее обновление: 07/02/2024 11:10:59',
            link: 'http://mseism.gsras.ru/EqInfo/RequestsHandler?cmd=toinfmsg&imid=256'
          },
        ]
      }
    }
  }
</script>

<style scoped>

  .news-header {
    font-size: 20px;
  }

  .news-article {
    border-bottom: 1px solid black;
    padding-top: 22px;
  }


  .news-title {

  }

  .news-body {

  }

  .news-meta {
    margin-top: 2px;
    font-size: 12px; float: right;
  }

  .news-link {
    color: #3c65a3;
  }
</style>