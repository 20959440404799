<template>
  <div>
    <div style="position: fixed; top: 220px; padding-left: 20px; z-index: 10;">
      <div>Для тестирования</div>
      <div @click="changeTo('login')">логин</div>
      <div @click="changeTo('registration')">регистрация</div>
      <div @click="changeTo('users')">пользователи</div>
      <div @click="changeTo('news')">новости</div>
    </div>
    <div style="display: flex; height: 80vh; ">
    <aside style="width: 20vw; z-index: 2">
      <Aside/>
    </aside>
    <main style="width: 80vw; padding: 10px; overflow-y: scroll">
      <Login
             v-show="currentComponent ==='login'"/>
      <Registration
             v-show="currentComponent ==='registration'"/>
      <Users
              v-show="currentComponent ==='users'"/>
      <News v-show="currentComponent === 'news'"/>
    </main>
    </div>
  </div>
</template>

<script>
  import Login from "./views/Login.vue";
  import Registration from "./views/Registration.vue";
  import Users from "./views/Users.vue";
  import Aside from "./components/Aside"
  import News from "./components/News";



  export default {
    name: 'test',
    components: {
      Login,
      Registration,
      Users,
      Aside,
      News
    },
    data() {
      return {
        currentComponent: '',
      }
    },
    mounted() {
      console.log('test mounted')
    },
    methods: {
      changeTo(name) {
        this.currentComponent = name
      }
    },
  }
</script>
